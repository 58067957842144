ul {
  display: block;
  margin-left: -10px;
}

ul li {
  display: block;
  position: relative;
  font-size: 1.5em;
  color: white;
}

ul li:not(:last-child) {
  margin-bottom: 16px;
}

ul li:before {
  content: "";
  position: absolute;
  top: 1.2em;
  left: -30px;
  margin-top: -0.9em;
  background: #f69ec4;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
